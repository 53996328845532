<template>
    <div class="preview-layer" v-if="visible" @click="closePreviewModal">
        <div class="preview-view">
            <RealPhonePreview @click.stop>
                <template v-slot:preview>
                    <div
                        style="
                            background-color: #fff;
                            height: 100%;
                            width: 300px;
                        "
                    >
                        <div class="box_body">
                            <div class="box_title">
                                {{ addActivityList.title }}
                            </div>
                            <template v-if="addActivityList.isEnroll">
                                <div class="box_content">
                                    <div class="tiele_">限报名人数</div>
                                    <div
                                        style="
                                            display: inline-block;
                                            color: #000;
                                            vertical-align: top;
                                            max-width: 214px;
                                        "
                                    >
                                        {{ addActivityList.limitCount }}
                                    </div>
                                </div>
                                <div class="box_content">
                                    <div class="tiele_">报名时间</div>
                                    <div
                                        style="
                                            display: inline-block;
                                            color: #000;
                                            max-width: 214px;
                                        "
                                    >
                                        {{ addActivityList.applyStartTime||addActivityList.enrollStartTime }} 至
                                        {{ addActivityList.applyEndTime || addActivityList.enrollEndTime}}
                                    </div>
                                </div>
                            </template>

                            <div class="box_content">
                                <div class="tiele_">活动时间</div>
                                <div style="display: inline-block; color: #000;max-width: 214px;vertical-align: top;">
                                    {{ addActivityList.startTime }} 至
                                    {{ addActivityList.endTime }}
                                </div>
                            </div>
                            <div class="box_content">
                                <div class="tiele_">活动地点</div>
                                <div style="display: inline-block; color: #000;max-width: 214px;">
                                    <a href="#">{{ addActivityList.place }}</a>
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;height: 10px;background: #f0f0f0;"></div>
                        <div class="box_body_content">
                            <div
                                class="content_titile"
                                v-html="addActivityList.content"
                            ></div>
                        </div>
                    </div>
                </template>
            </RealPhonePreview>
            <slot name="btnGroup"></slot>
        </div>
    </div>
</template>

<script>
import RealPhonePreview from "@/components/RealPhonePreview";

const MEETING_ROOM_TYPE = {
    PUBLIC: 2,
    PRIVATE: 1,
};
export default {
    name: "activityPreview",
    components: {
        RealPhonePreview,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        company: String,
        addActivityList: Object,
    },
    data() {
        return {
            MEETING_ROOM_TYPE,
        };
    },
    methods: {
        // 关闭弹窗
        closePreviewModal() {
            this.$emit("closeModal");
        },
    },
    mounted() {},
};
</script>
<style scoped>
.box_body {
    padding: 10px;
    background-color: #fff;
}
.box_title {
    font-size: 13px;
    /*font-weight: 600;*/
    margin-bottom: 6px;
    color: #000;
}
.tiele_ {
    display: inline-block;
    width: 70px;
    min-width: 54px;
    margin-right: 10px;
    color: #999;
    flex: 0 0 70px;
}

.box_content {
    display: flex;
    flex-flow: row nowrap;
}

.box_content a {
    text-decoration: underline;
    color: #1dafff;
}

.content_titile {
    margin-top: 10px;
    background-color: #fff;
    padding: 10px;
}
.meeting-room-info-item {
    background-color: #fff;
}
.meeting-room-info-item img {
    width: 300px;
    padding: 10px;
}
.phone-view-content {
    background-color: #fff !important;
}
</style>
