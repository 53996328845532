<template>
    <div id="proclamation_">
        <div class="filter-panel">
            <CSSelect
                style="width: 185px; border-radius: 4px; margin-right: 0;"
            >
                <el-date-picker
                    v-model="startDate"
                    type="date"
                    style="width: 157px;"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span>
            <CSSelect
                style="width: 185px; border-radius: 4px;"
            >
                <el-date-picker
                    v-model="endDate"
                    type="date"
                    style="width: 157px;"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <input
                type="text"
                class="cs-input"
                style="margin-bottom: 15px; border-radius: 4px;"
                placeholder="搜索活动名称"
                v-model="userInfo"
            />
            <CSSelect
                style="border-radius: 4px; margin-right: 30px;"
            >
                <select v-model="activityStates" style="padding-left: 5px;">
                    <option value="">状态不限</option>
                    <option
                        v-for="(state, key) in activityState"
                        :key="key"
                        :value="key"
                        style="color: #000"
                    >
                        {{ state }}
                    </option>
                </select>
            </CSSelect>

            <button class="btn_query" style="width: 50px; height: 30px; padding: 0;" @click="getActivity()">查询</button>
        </div>
        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <div class="btn btn-primary sticky-right" @click="openAddActivityDialog()">
                            <svg
                                class="icon"
                                aria-hidden="true"
                            >
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            添加活动
                        </div>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>活动名称</th>
                        <th>活动类型</th>
                        <th>是否需要报名</th>
                        <th>报名时间</th>
                        <th>活动时间</th>
                        <th>状态</th>
                        <th>数据统计</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr
                        v-for="item in activityList"
                        :key="item.id"
                    >
                        <td class="text-center date-td">
                            {{ item.createTime }}
                        </td>
                        <td class="formWidth text-center">
                            <a
                                href="#"
                                @click="
                                                    lookActivityInformation(
                                                        item.postId, item.content, item.type
                                                    )
                                                "
                                class="btn btn-link btn-content"
                            >
                                {{ item.title }}
                            </a>
                        </td>
                        <td class="text-center">
                            {{item.type ? '抢苹果' : '其他'}}
                        </td>
                        <td class="text-center">
                            {{
                                whetherNeedState[item.isEnroll]
                            }}
                        </td>
                        <td class="text-center date-td">
                            <template v-if="item.enrollStartTime || item.enrollEndTime">
                                <p style="margin-bottom: 0">
                                    {{ item.enrollStartTime }}
                                </p>
                                {{ item.enrollEndTime }}
                            </template>
                            <template v-else>-</template>

                        </td>
                        <td class="text-center date-td">
                            <template v-if="item.startTime || item.endTime">
                                <p style="margin-bottom: 0">
                                    {{ item.startTime }}
                                </p>
                                {{ item.endTime }}
                            </template>
                            <template v-else>-</template>
                        </td>
                        <td class="text-center">
                            {{ activityState[item.state] }}
                        </td>
                        <td class="formWidth text-center">
                                            <span
                                                @click="lookActivitys(item)"
                                                class="allow-click"
                                            >
                                                查看
                                            </span>
                        </td>
                        <td>
                            <div class="btn-group">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul
                                    class="dropdown-menu"
                                    style="width: 120px; padding: 0;"
                                >
                                    <li v-if="item.isEnroll">
                                        <a
                                            @click="
                                                                downloadPhotos(
                                                                    item.id
                                                                )
                                                            "
                                            style="width: 100%; padding: 3px 0;"
                                        >签到二维码</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            @click="
                                                                deleteActivity = true;
                                                                currentItemId =
                                                                    item.postId;
                                                            "
                                            style="width: 100%; padding: 3px 0;"
                                        >删除活动</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination
                name="Pagination"
                component="Pagination"
            ></Pagination>
        </div>
        <!-- 添加活动弹出框 -->
        <!-- <div style="border-top: 1px solid #f0f0f0; padding-top: 20px; text-align: center;">
                    <button class="cancel_" @click="addActivity = false">
                        取消
                    </button>
                    <button
                        v-if="redPacketParams.activityType == 2"
                        class="preview_"
                        @click="previewActivityInfo"
                    >
                        预览
                    </button>
                    <button v-else class="preview_" @click="addRedPacketActivity">
                        添加
                    </button>

                </div> -->
        <CSDialog
            :dialogVisible="addActivity"
            dialogWidth="980px"
            dialogTitle="添加活动"
            @onCancel="addActivity = false"
            @onClose="addClose"
            @onConfirm="addRedPacketActivity"
        >
            <div slot="dialog-content" style="padding: 20px 30px;">
                <div style="margin-bottom: 23px;">
                    <div class="notice_title">活动名称</div>
                    <input
                        class="notice_name"
                        style="padding-left: 10px; vertical-align: middle;"
                        type="text"
                        v-model="addActivityList.title"
                        placeholder="限20个字"
                        maxlength="20"
                    />
                </div>
                <div>
                    <div
                        class="notice_title"
                        style="vertical-align: top; padding-top: 0; margin-top: 0; height: 24px; line-height: 24px;"
                    >活动列表配图</div>
                    <div style="display: inline-block; margin-bottom: 20px; vertical-align: top;">
                        <div
                            class="photo-view"
                            style="width: 125px; height: 90px;"
                            v-if="addActivityList.picture !== ''"
                        >
                            <img
                                :src="addActivityList.picture"
                                style="width: 125px; height: 90px"
                            />
                            <i
                                class="icon-close"
                                @click="addActivityList.picture = ''"
                            ></i>
                        </div>
                        <div
                            class="upload-photo"
                            style="width: 125px; height: 90px"
                            @click="triggerChoosePhoto"
                            v-if="addActivityList.picture == ''"
                        >
                            <div class="text-center">
                                <img
                                    src="../assets/upload.png"
                                    alt=""
                                    style="margin: 5px auto 5px"
                                />
                                <p style="color: #999;">上传照片</p>
                                <p style="color: #999;">尺寸250*180</p>
                            </div>
                        </div>
                        <input
                            type="file"
                            class="file"
                            accept="images/*"
                            id="uploadStaffPhoto"
                            hidden
                            @change="chooseStaffPhoto($event)"
                        />
                    </div>
                </div>
                <div style="margin-bottom: 13px;">
                    <div style="font-size: 24px; width: 144px; margin-right: 20px; text-align: right; display: inline-block; vertical-align: middle;">
                        活动类型
                    </div>
                    <div style="display: inline-block; vertical-align: middle;">
                        <span style="margin-right: 20px;">
                            <input
                                type="radio"
                                v-model="redPacketParams.activityType"
                                :value="1"
                            />
                            <span
                                style="
                                    font-size: 24px;
                                    font-style: normal;
                                    color: #000;
                                "
                            >
                                抢苹果
                            </span>
                        </span>
                        <span>
                            <input
                                type="radio"
                                v-model="redPacketParams.activityType"
                                :value="2"
                            />
                            <span
                                style="
                                    font-size: 24px;
                                    font-style: normal;
                                    color: #000;
                                "
                            >
                                其它
                            </span>
                        </span>
                    </div>
                </div>
                <template v-if="redPacketParams.activityType == 1">
                    <div style="margin-bottom: 23px;">
                        <div class="notice_title" style="margin-top: 0; padding-top: 0; vertical-align: middle;">
                                选择苹果红包
                        </div>
                        <CSSelect
                            i-width="36px"
                            height="40px"
                        >
                            <select :style="{'padding-left': '6px', 'width': '285px', 'font-size': '24px', 'color': redPacketParams.appleId ? '#000' : '#999' }"
                                    v-model="redPacketParams.appleId"
                            >
                                <option value="">请选择</option>
                                <option :value="appleRed.id" v-for="appleRed in appleRedpacketActivities" :key="appleRed.id">{{appleRed.name}}</option>
                            </select>
                        </CSSelect>
                    </div>
                </template>
                <template v-if="redPacketParams.activityType == 2">
                    <div>
                        <div class="notice_title" style="vertical-align: top; padding-top: 0;">
                            是否需要报名
                        </div>
                        <div style="display: inline-block; margin-top: 3px; margin-right: 20px; margin-bottom: 19px;">
                            <i>
                                <span style="margin-right: 20px;">
                                    <input
                                        type="radio"
                                        v-model="addActivityList.isEnroll"
                                        :value="APPLY_TYPE.YES"
                                    />
                                    <span
                                        style="
                                            font-size: 24px;
                                            font-style: normal;
                                            color: #000;
                                            margin: 0 10px;
                                        "
                                        >是</span
                                    >
                                </span>
                                <span>
                                    <input
                                        type="radio"
                                        v-model="addActivityList.isEnroll"
                                        :value="APPLY_TYPE.NO"
                                    />
                                    <span
                                        style="
                                            font-size: 24px;
                                            font-style: normal;
                                            color: #000;
                                            margin: 0 10px;
                                        "
                                        >否</span
                                    >
                                </span>
                            </i>
                            <template v-if="addActivityList.isEnroll == 1">
                                <div style="margin-bottom: 20px;">
                                    <div
                                        class="notice_title"
                                        style="display: inline-block; vertical-align: middle;"
                                    >
                                        限制报名人数
                                    </div>
                                    <div style="display: inline-block; vertical-align: middle;">
                                        <i>
                                            <span style="margin-right: 20px;">
                                                <input
                                                    type="radio"
                                                    v-model="
                                                        addActivityList.isWillnum
                                                    "
                                                    :value="
                                                        ROBAPPLE_NUMBER.NO_RESTRICT
                                                    "
                                                    style="margin-right: 10px;"
                                                />
                                                <span
                                                    style="
                                                        font-size: 24px;
                                                        font-style: normal;
                                                        color: #000;
                                                    "
                                                    >不限制</span
                                                >
                                            </span>
                                            <span>
                                                <input
                                                    type="radio"
                                                    v-model="
                                                        addActivityList.isWillnum
                                                    "
                                                    :value="
                                                        ROBAPPLE_NUMBER.RESTRICT
                                                    "
                                                    style="margin-right: 10px;"
                                                />
                                                <span
                                                    style="
                                                        font-size: 24px;
                                                        font-style: normal;
                                                        color: #000;
                                                    "
                                                    >限制</span
                                                >
                                            </span>
                                        </i>
                                    </div>
                                    <div
                                        v-if="
                                            addActivityList.isWillnum ==
                                            ROBAPPLE_NUMBER.RESTRICT
                                        "
                                        style="display: inline-block; font-size: 24px; margin-left: 20px; padding-left: 5px; margin-bottom: 6px;"
                                    >
                                        <CSSelect
                                            iWidth="36px"
                                            height="40px"

                                        >
                                            <select
                                            v-model="addActivityList.limitCount"
                                            :style="{
                                                color:
                                                    addActivityList.limitCount != ''
                                                        ? '#000'
                                                        : '#999',
                                            }"
                                        >
                                            <option value="" style="color: #000">
                                                请选择人数
                                            </option>
                                            <option
                                                v-for="n in 100"
                                                :key="n"
                                                :value="n"
                                                style="color: #000"
                                            >
                                                {{ n }}
                                            </option>
                                        </select>
                                        </CSSelect>
                                    </div>
                                </div>

                                <div style="margin-bottom: 23px;">
                                    <div class="notice_title">报名开始时间</div>
                                    <div
                                        style="margin-right: 10px; display: inline-block;"
                                    >
                                        <CSSelect
                                            iWidth="36px"
                                            height="40px"
                                        >
                                            <el-date-picker
                                                v-model="
                                                    addActivityList.enrollStartTime
                                                "
                                                style="font-size: 24px; margin-bottom: 7px;"
                                                type="datetime"
                                                value-format="yyyy-MM-dd HH:mm"
                                                format="yyyy-MM-dd HH:mm"
                                                placeholder="请选择"
                                                :editable="false"
                                                prefix-icon="el-icon-time"
                                            ></el-date-picker>
                                        </CSSelect>
                                    </div>
                                </div>

                                <div>
                                    <div class="notice_title">报名结束时间</div>
                                    <div
                                        style="margin-right: 10px; display: inline-block;"
                                    >
                                        <CSSelect
                                            iWidth="36px"
                                            height="40px"
                                        >
                                            <el-date-picker
                                                v-model="addActivityList.enrollEndTime"
                                                type="datetime"
                                                value-format="yyyy-MM-dd HH:mm"
                                                format="yyyy-MM-dd HH:mm"
                                                placeholder="请选择"
                                                style="font-size: 24px; margin-bottom: 7px;"
                                                :editable="false"
                                            ></el-date-picker>
                                        </CSSelect>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>

                <div style="margin-bottom: 23px;">
                    <div>
                        <div class="notice_title">活动开始时间</div>
                        <div style="margin-right: 10px; display: inline-block;">
                            <CSSelect
                                iWidth="36px"
                                height="40px"
                            >
                                <el-date-picker
                                    v-model="addActivityList.startTime"
                                    type="datetime"
                                    style="font-size: 24px; margin-bottom: 7px;"
                                    value-format="yyyy-MM-dd HH:mm"
                                    format="yyyy-MM-dd HH:mm"
                                    placeholder="请选择"
                                    :editable="false"
                                ></el-date-picker>
                            </CSSelect>
                        </div>
                    </div>
                </div>
                <div style="margin-bottom: 13px;">
                    <div>
                        <div class="notice_title">活动结束时间</div>
                        <div style="margin-bottom: 10px; display: inline-block;">
                            <CSSelect
                                iWidth="36px"
                                height="40px"
                            >
                                <el-date-picker
                                    v-model="addActivityList.endTime"
                                    style="font-size: 24px; margin-bottom: 7px;"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm"
                                    format="yyyy-MM-dd HH:mm"
                                    placeholder="请选择"
                                    :editable="false"
                                ></el-date-picker>
                            </CSSelect>
                        </div>
                    </div>
                </div>
                <template v-if="redPacketParams.activityType == 2">
                    <div style="margin-bottom: 23px;">
                        <div class="notice_title" style="vertical-align: middle;">活动地点</div>
                        <input
                            class="notice_name"
                            style="padding-left: 5px; padding-left: 10px; vertical-align: middle;"
                            type="text"
                            v-model="addActivityList.place"
                            placeholder="限16个字"
                            maxlength="16"

                        />
                    </div>
                    <div>
                        <div class="notice_title" style="vertical-align: top; padding-top: 0; height: 24px; line-height: 24px;">
                            活动内容
                        </div>
                        <div
                            class="edit-visiting-modal-content-item-content"
                            style="width: 720px; display: inline-block; vertical-align: top;"
                        >
                            <WangEditor
                                parentComponent="ActivityAdministrator"
                                :isSensitive="true"
                                @callback-photos="updateSensitivePhotos"
                            ></WangEditor>
                            <div>
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-menua-zu92"></use></svg
                                ><span
                                    style="
                font-weight: 400;
                line-height: 20px;
                color: #999999;
                margin-left: 5px;
              "
                            >活动内容请至少上传一张配图</span
                            >
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <template v-slot:dialog-footer>
                <button
                    v-if="redPacketParams.activityType == 2"
                    class="preview_"
                    @click="previewActivityInfo"
                >
                    预览
                </button>
                <button v-else :class="['preview_', {disabled: isSubmitting}]" @click="addRedPacketActivity">
                    添加
                </button>
                <button class="cancel_" @click="() =>{addActivity = false;activityModal = false}">
                    取消
                </button>
            </template>
        </CSDialog>
        <ActivityPreview
            :visible="previewModalInfo.visible"
            @closeModal="previewModalInfo.onClose"
            :addActivityList="previewModalInfo.data"
        >
            <template v-slot:btnGroup v-if="activityModal">
                <div class="text-align: center;padding-top: 10px;">
                    <button
                        style="width: 180px; height: 40px"
                        class="btn btn-primary mr-5"
                        @click="prevStep"
                    >
                        上一步
                    </button>
                    <!-- addpreviewEditMeetingRoomInfo -->
                    <button
                        style="width: 180px; height: 40px"
                        :class="['btn', 'btn-primary', {disabled: isSubmitting}]"
                        @click="commitActivityInfo"
                    >
                        确认
                    </button>
                </div>
            </template>
            <template v-slot:btnGroup v-else>
                <div style="text-align: center;">
                    <button
                        class="btn btn-primary"
                        @click="previewModalInfo.onClose();"
                    >
                        关闭
                    </button>
                </div>
            </template>
        </ActivityPreview>
        <!-- 删除活动弹出框 -->
        <CSDialog
            dialogTitle="提示" dialog-header-class=" "
            dialogWidth="480px" dialog-confirm-btn-text="确定"
            :dialogVisible="deleteActivity"
            @onClose="deleteActivity = false"
            @onConfirm="deleteActivitys"
            dialog-header-icon="icon-menua-zu13"
        >
            <div slot="dialog-content" style="text-align: center; font-size: 24px; padding: 20px 30px;">
                <span>确定删除吗?</span>
            </div>
        </CSDialog>
        <!--    查看苹果红包    -->
        <CSDialog
            dialog-title="查看苹果红包" dialog-header-class=" "
            dialog-width="560px" :dialog-show-confirm-btn="false"
            dialog-cancel-btn-text="关闭"
            :dialog-visible="lookAppleVisible"
            @onClose="lookAppleVisible = false"
        >
            <template v-slot:dialog-content>
                <div class="form-panel">
                    <div class="form-item">
                        <div
                            class="form-item-label"
                        >
                            发布时间
                        </div>
                        <div class="form-item-content">
                            {{redPack.createTime || '-'}}
                        </div>
                    </div>
                    <div class="form-item">
                        <div
                            class="form-item-label"
                        >
                            苹果红包名称
                        </div>
                        <div class="form-item-content">
                            {{redPack.name || '-'}}
                        </div>
                    </div>
                    <div class="form-item">
                        <div
                            class="form-item-label"
                        >
                            苹果数量
                        </div>
                        <div class="form-item-content">
                            {{redPack.amount || '-'}}
                        </div>
                    </div>
                    <div class="form-item">
                        <div
                            class="form-item-label"
                        >
                            可抢人数
                        </div>
                        <div class="form-item-content">
                            {{redPack.shares || '-'}}
                        </div>
                    </div>
                    <div class="form-item">
                        <div
                            class="form-item-label"
                        >
                            被领取数量
                        </div>
                        <div class="form-item-content">
                            {{redPack.grabbedAmount}}
                        </div>
                    </div>
                    <div class="form-item">
                        <div
                            class="form-item-label"
                        >
                            未被领取数量
                        </div>
                        <div class="form-item-content">
                            {{redPack.noGrabbedAmount}}
                        </div>
                    </div>
                    <div class="form-item">
                        <div
                            class="form-item-label"
                        >
                            备注
                        </div>
                        <div class="form-item-content">
                            {{redPack.comment || '-'}}
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
import {
    refundMeetingRoomUrl,
    adminQueryCensusUrl,
    getadminQueryUrl,
    deleteActivityUrl,
    addRedPacketActivityUrl,
    queryBuildingUrl,
    addActivityUrl,
    downloadQrCodeUrl,
    queryAppleRedPackUrl,
    userQueryAnPostUrl,
    downloadFileOssDomain,
    redPackByIdUrl,
    queryRedPackListUrl,
} from "@/requestUrl";
import Pagination from "@/components/Pagination.vue";
import WangEditor from "@/components/WangEditor";
import ActivityPreview from "@/components/phonePreview/ActivityPreview";
import CSSelect from "@/components/common/CSSelect";
import CSDialog  from "@/components/common/CSDialog";
import dayjs from "dayjs";
import CSTable from "@/components/common/CSTable";

const ACTIVITY_TYPE = {
    ROBAPPLE: 1,
    RESTS: 2,
};
const ROBAPPLE_NUMBER = {
    RESTRICT: 1,
    NO_RESTRICT: 0,
};
const APPLY_TYPE = {
    YES: 1,
    NO: 0,
};
export default {
    name: "RefundMeetingRoomCharge",
    components: {
        CSTable,
        WangEditor,
        ActivityPreview,
        CSSelect,
        CSDialog,
        Pagination,
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getActivityDown);
    },
    deactivated(){
        window.removeEventListener("keydown",this.getActivityDown);
    },
    data() {
        return {
            isSubmitting: false,
            filterHeight: 0,
            currentItemId: "", //帖子id
            ACTIVITY_TYPE,
            ROBAPPLE_NUMBER,
            APPLY_TYPE,
            searchAppleRedPackeLoading: false,
            //  添加红包活动时的部分参数
            redPacketParams: {
                activityType: 1,
                appleId: "",
            },
            addActivity: false, // 控制添加活动弹出框的显示与隐藏
            statistics: false, //控制数据统计弹出框的显示与隐藏
            deleteActivity: false, //控制删除活动弹出框的显示与隐藏
            activityModal: false, //控制预览弹出框的显示与隐藏
            startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
            //开始时间 必填
            endDate: dayjs().format("YYYY-MM-DD"), //结束时间   必填
            userInfo: "", //选择查询时搜索的数据
            activityStates: "", //存放用户选择的活动状态
            state: "",
            activityList: [], //获取到的活动数据
            statisticsList: [], //获取到数据统计的数据
            searchContent: "", //数据统计搜索框的内容
            activityState: {
                1: "未开始",
                2: "已开始",
                3: "已结束",
            },
            whetherNeedState: {
                1: "需要",
                0: "不需要",
            },
            // 返回的数据统计签到状态的处理
            signState: [
                { id: 0, name: "未签到" },
                { id: 1, name: "已签到" },
            ],
            signStates: {
                0: "未签到",
                1: "已签到",
            },

            orderState: [
                { state: ACTIVITY_TYPE.ROBAPPLE, name: "抢苹果" },
                { state: ACTIVITY_TYPE.RESTS, name: "其他" },
            ],
            checkedTimeoutOptions: [],
            // 添加活动里面的数据
            addActivityList: {
                regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                title: "", //活动名称 必填
                picture: "", //活动列表配图
                isEnroll: "", //是否需要报名 必填  1.需要0.不需要
                isWillnum: 0, //是否限制报名人数 1.限制0.不限制
                limitCount: 0, //活动限报名人数
                enrollStartTime: "", //报名开始时间
                enrollEndTime: "", //报名结束时间
                startTime: "", //活动开始时间 必填
                endTime: "", //活动结束时间 必填
                place: "", //活动地点 必填
                content: "", //活动内容 必填
                photos: [], //活动配图
                userId: this.$vc.getCurrentStaffInfo().id, //员工id 必填
            },
            // 预览弹窗相关信息
            previewModalInfo: {
                visible: false,
                data: {},
                onClose: () => {
                    this.previewModalInfo.visible = false;
                },
            },
            appleRedpacketActivities: [],
            lookAppleVisible: false,
            redPack: {},
        };
    },

    created(){
         window.addEventListener("keydown",this.getActivityDown);
        this.$vc.on(this.$route.path, "pagination","page_event",(_currentPage)=>{
            this.getActivity(_currentPage);
        })
        this.getAppleRedPacketList();
    },
    methods: {
        prevStep() {
            this.previewModalInfo.onClose();
            this.addActivity = true;
            this.$nextTick(() => {
                this.$vc.emit(this.$route.path, "wangEditor", "setWangEditorContent", this.addActivityList.content);
            })
        },
        getActivityDown(e){
            if(e.keyCode == 13){
                this.getActivity();
            }
        },
        previewActivityInfo() {
            if (!this.addActivityList.title || this.addActivityList.title === ' ') {
                this.$vc.message('请填写活动名称');
                return ;
            }
            if (this.addActivityList.photos.length === 0) {
                this.$vc.toast('请上传配图');
                return ;
            }
            if (!this.addActivityList.picture) {
                this.$vc.message('请上传活动列表配图');
                return ;
            }
            this.previewModalInfo.visible = true;
            this.addActivity = false;
            this.activityModal = true;
            this.$vc.copyObject(this.addActivityList, this.previewModalInfo.data);
            this.previewModalInfo.data = {...this.addActivityList};
        },
        addRedPacketActivity() {
            if (!this.addActivityList.title || this.addActivityList.title === ' ') {
                this.$vc.message('请填写活动名称');
                return ;
            }
            if (!this.addActivityList.picture) {
                this.$vc.message('请上传活动列表配图');
                return ;
            }
            let startTimeStamp = new Date(this.addActivityList.startTime).getTime();
            let endTimeStamp = new Date(this.addActivityList.endTime).getTime();
            if (startTimeStamp > endTimeStamp) {
                this.$vc.message('活动开始时间不能大于活动结束时间');
                return;
            }
            this.isSubmitting = true;
            this.$fly
                .post(addRedPacketActivityUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                    title: this.addActivityList.title, //活动名称 必填
                    picture: this.addActivityList.picture, //活动列表配图
                    startTime: this.addActivityList.startTime, //活动开始时间 必填
                    endTime: this.addActivityList.endTime, //活动结束时间 必填
                    appleId: this.redPacketParams.appleId, //所选苹果id 必填
                    userId: this.$vc.getCurrentStaffInfo().id, //员工id 必填
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("添加成功");
                    this.addActivity = false;
                    this.getActivity();
                }).finally(() => this.isSubmitting = false);
        },
        //  获取苹果红包数据
        getAppleRedPacketList(keyword) {
            this.searchAppleRedPackeLoading = true;
            this.$fly
                .get(queryRedPackListUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                })
                .then((res) => {
                    this.searchAppleRedPackeLoading = false;
                    if (res.code !== 0) {
                        return;
                    }
                    this.appleRedpacketActivities = res.data;
                });
        },
        updateSensitivePhotos(src) {
            this.addActivityList.photos.push(src);
        },
        // 查看活动信息
        async lookActivityInformation(postId, id, type) {
            if (type) {
                const res = await this.$fly.get(redPackByIdUrl, { id });
                if (res.code != 0) {
                    return;
                }
                this.redPack = res.data;
                this.lookAppleVisible = true;
            } else {
                const res = await this.getActivityParticulars(postId);
                if (res.code != 0) {
                    this.$vc.message("获取到活动详细信息失败");
                    return;
                }
                this.previewModalInfo.data = res.data;
                this.previewModalInfo.visible = true;
            }

        },
        // 获取活动详情
        getActivityParticulars(id) {
            return this.$fly
                .post(userQueryAnPostUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                    id, //帖子id/活动帖子id  必填
                    userId: this.$vc.getCurrentStaffInfo().id, //用户id 必填
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    res.data.photos = JSON.parse(res.data.photos);
                    return res;
                });
        },
        // 获取楼栋信息
        getBuilding() {
            this.$fly.post(queryBuildingUrl, {}).then((res) => {
                if (res.code != 0) {
                    return;
                }
                this.building = res.data;
            });
        },
        // 提交添加的活动
        addPreviewActivityInfo(params) {
            params.photos =
                params.photos.length > 0 ? JSON.stringify(params.photos) : "";

            return this.$fly.post(addActivityUrl, params).then((res) => {
                if (res.code != 0) {
                    return;
                }
                this.$vc.toast("操作成功");
                this.closeMeetingRoomView();
                this.getActivity();
                return ;
            })
                .catch(err => err);
        },
        // 关闭预览弹窗
        closeMeetingRoomView() {
            this.previewModalInfo.onClose();
            this.activityModal = false;
        },
        // 添加活动
        async commitActivityInfo() {
            if (this.isSubmitting) {
                this.$vc.message('请不要重复提交');
                return ;
            }
            let params = { ...this.addActivityList };
            this.isSubmitting = true;
            if (this.previewModalInfo.data.id) {
                //  HACK：不会执行的部分怀疑是想写修改的，日后看有没有报错再修改  ~：求稳总是没错的
                params.id = this.previewModalInfo.data.id;
                params.photos = JSON.stringify(params.photos);
                params.regionId = this.$vc.getCurrentRegion().communityId;
                await this.amendVisitingServiceItem(
                    this.$vc.removeNullProperty(params)
                );
            } else {
                await this.addPreviewActivityInfo(
                    this.$vc.removeNullProperty(params)
                );
            }
            this.isSubmitting = false;
        },
        async lookActivitys(item) {
            this.$router.push({name: "DataStatistics", params: {id: item.id, currentDataStatistics: JSON.stringify({
                        uvCount: item.uvCount,
                        applyCount: item.applyCount,
                        registerCount: item.registerCount
                    })}});
        },

        // 添加活动
        openAddActivityDialog() {
            this.addActivity = true;
            this.addActivityList = {
                    regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                    title: "", //活动名称 必填
                    picture: "", //活动列表配图
                    isEnroll: "", //是否需要报名 必填  1.需要0.不需要
                    isWillnum: 0, //是否限制报名人数 1.限制0.不限制
                    limitCount: 0, //活动限报名人数
                    enrollStartTime: "", //报名开始时间
                    enrollEndTime: "", //报名结束时间
                    startTime: "", //活动开始时间 必填
                    endTime: "", //活动结束时间 必填
                    place: "", //活动地点 必填
                    content: "", //活动内容 必填
                    photos: [], //活动配图
                    userId: this.$vc.getCurrentStaffInfo().id, //员工id 必填
            };
            // this.previewModalInfo.data = this.addActivityList;
            // this.$vc.emit(this.$route.path, "wangEditor", "setWangEditorContent", "");
            this.activityModal = true;
        },

        addClose() {
            this.redPacketParams.appleId = '';
            this.addActivity = false;
        },
        // 查询数据统计里的信息
        getDataStatistics(activityId) {
            const params = {
                regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                activityId, //活动id 必填
                search: this.searchContent, //搜索内容 姓名或手机号
            };
            if (this.activityStates !== "") {
                params.state = this.activityStates;
            }
            return this.$fly.post(adminQueryCensusUrl, params).then((res) => {
                if (res.code != 0) {
                    return;
                }
                this.statisticsList = res.data;
                return res.data;
            });
        },
        // 后台查询活动
        getActivity(pageNo = 1, pageSize = 10, itemId) {
            const params = {
                regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                startDate: this.startDate + " 00:00", //查询开始时间 必填
                endDate: this.endDate + " 23:59", //查询结束时间 必填
                title: this.userInfo, //活动名称
                pageNo,
                pageSize,
            };
            if (this.activityStates !== "") {
                params.state = this.activityStates;
            }
            this.$fly.post(getadminQueryUrl, params).then((res) => {
                if (res.code != 0) {
                    return;
                }
                if(pageNo === 1){
                    this.$vc.emit(this.$route.path, "pagination","init",{
                        total: res.data.total,
                        currentPage: pageNo,
                        pageSize: pageSize,
                    })
                }
                this.activityList = res.data.datas;
            });
        },
        // 后台删除活动
        deleteActivitys() {
            this.$fly
                .post(deleteActivityUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                    id: this.currentItemId, //帖子id 必填
                    userId: this.$vc.getCurrentStaffInfo().id, //操作人id 必填
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    console.log("res", "删除活动成功");
                    this.deleteActivity = false;
                    this.getActivity();
                });
        },
        // 下载图片
        downloadPhotos(id) {
            this.$fly
                .post(downloadQrCodeUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                    id, //活动id 必填
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    if (res.data) {
                        window.open(downloadFileOssDomain + "/" + res.data);
                        console.log("res.data", "下载成功");
                    }else {
                        this.$vc.toast("下载失败，二维码不存在");
                    }
                });
        },
        //  调起选择文件
        triggerChoosePhoto() {
            $("#uploadStaffPhoto").trigger("click");
        },
        // 上传图片
        async uploadPhoto(base64) {
            return new Promise((reslove, reject) => {
                this.$vc.http.post(
                    "uploadImg",
                    "upload",
                    {
                        img: base64,
                    },
                    {
                        headres: {
                            "Content-Type": "application/json",
                        },
                    },
                    (resStr, res) => {
                        reslove(res.body.fileSaveName);
                    },
                    (errText, err) => {
                        reject(errText);
                    }
                );
            });
        },
        // 选择文件后处理
       async chooseStaffPhoto(event) {
            var photoFiles = event.target.files;
            if (photoFiles && photoFiles.length > 0) {
                // 获取目前上传的文件
                var file = photoFiles[0]; // 文件大小校验的动作
                if (file.size > 1024 * 1024 * 2) {
                    this.$vc.toast("图片大小不能超过 2MB!");
                    return false;
                }
                // var reader = new FileReader(); //新建FileReader对象
                // reader.readAsDataURL(file); //读取为base64
                // reader.onloadend = async (e) => {
                //     const photo = await this.uploadPhoto(reader.result).catch(
                //         (err) => {
                //             console.error("上传照片失败");
                //             return "";
                //         }
                //     );
                //     this.addActivityList.picture = photo;
                // };
                let formData = new FormData(); //新建FormData对象
                formData.append("files", photoFiles[0]);
                formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
                formData.append("compress", "true");
                const photo = await this.Constants.uploadFileOne(formData);
                if (photo) {
                    event.target.value = ""
                    this.addActivityList.picture = this.Constants.BUCKET_NAMES.IMG.visitUrl + photo;
                }
            }
        },
        deletePhotos(index) {
            this.addActivityList.photos.splice(index, 1);
        },
        // 关闭预览弹窗
        closeActivity() {
            this.previewModalInfo.onClose();
            this.activityModal = false;
        },
    },
    mounted() {
        this.getActivity();
        this.getBuilding();
        this.$vc.on(this.$route.path, "ActivityAdministrator", "changeEditor", (html) => {
            this.addActivityList.content = html;
        });
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
};
</script>

<style scoped>
#proclamation_ .el-dialog__header {
    padding: 0;
}
#proclamation_ .el-dialog__footer {
    padding: 30px 0;
    margin: auto;
    text-align: center;
    border-top: 1px solid #999;
}
</style>

<style lang="stylus" scoped>
.el-date-editor.el-input
  width 260px
i
    font-style normal
.underline_
    text-decoration underline
.btn_query
    position relative
    top 1.4px
    left 0
    width 50px
    height 31px
    background #1ab394
    box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.2)
    border-radius 4px
    border none
    font-size 14px
    color #fff
.filter-panel
    .cs-input
        border 1px solid #979797
        border-radius 1px
        vertical-align middle
        width 240px
        height 30px
        padding 0 10px
        box-sizing border-box
.cancel_
    width 80px
    height 40px
    background #fff
    border-radius 6px
    color #1ab394
    border 1px solid #1ab394
    font-size 24px
    box-sizing border-box
    margin-left 60px
.preview_
    width 80px
    height 40px
    background #1ab394
    box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.3)
    border-radius 6px
    color #fff
    font-size 24px
    border none
.notice_name
    width 400px
    height 40px
    font-size 24px
    border 1px solid #999
    border-radius 4px
.notice_title
    font-size: 24px
    width: 144px
    margin-right: 20px
    text-align: right
    display: inline-block
    vertical-align: middle
/*.cs-select
    width 251px*/
/deep/.el-select .el-input .el-input__inner
    border none
    font-size 24px
/deep/.el-select .el-input .el-input__inner::-webkit-input-placeholder
    color #999
/deep/.cs-select .el-input .el-input__inner::-webkit-input-placeholder
    color #999
.cs-select .icon
    width 36px
    line-height var(--icon-line-height, 40px)
.query_statistics .cs-select .icon
    width 36px
    line-height 26px
.attentionMatters p
    margin-bottom 0
    margin-left 30px
    color #999
    font-size 20px
.query_statistics .cs-select
    width 180px
    height 30px
    font-size 14px
    line-height 26px !important
.query_
    width 50px
    height 30px
    background #1ab394
    box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.2)
    border-radius 4px
    border none
    color #fff
.el-dialog .footable
    margin-top 30px
    margin-left 27px
    width 1200px
.determine
    width 80px
    height 40px
    background #1ab394
    box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.3)
    border-radius 6px
    color #fff
    border none
    font-size 24px
.btn-warn
    width: 80px;
    background-color: #FF9F00;
    color: #fff
    font-size: 24px
    line-height: 40px
    padding: 0
    border-radius: 4px
.form-panel
    padding 27px 30px 30px 30px

    .form-item
        margin-bottom 25px
        font-size 0

        &-label
            text-align right
            width 145px
            display inline-block
            margin-right 40px
            vertical-align middle
            font-size 24px

            &.top
                vertical-align top

        &-content
            display inline-block
            vertical-align middle
            font-size 24px

            .cs-input
                &::placeholder
                    color #999
                padding-left 10px
                border-radius 4px
                margin 0
                border 1px solid #979797

input::-webkit-input-placeholder
    color: #999;
input::-moz-placeholder
    color: #999;
input::-moz-placeholder
    color: #999;
input::-ms-input-placeholder
    color: #999;
</style>
